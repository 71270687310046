<template>
  <div class="m-0">
    <Alert />
    <div class="titre-container rs-title-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr rs-fs-2000">Détail déclaration</h1>
    </div>
    <div class="text-left rs-mb-15">
      <a
        href="#"
        @click="goPrevious($event)"
        class="fr-link p-0 color-pr fs-875 rs-mb-625"
        title="Retour aux declarations"
        ><i class="ri-arrow-left-line ml-1"></i>Retour aux déclarations</a
      >
    </div>
    <div v-if="item">
      <table class="table table-borderless">
        <caption>
          Liste des déclarations
        </caption>
        <thead class="bg-grey">
          <tr class="bt-dark selected-declaration">
            <th scope="col" class="ml-2">Taxe / Impôt</th>
            <th scope="col">Période de référence</th>
            <th scope="col">Date limite de dépôt</th>
            <th scope="col" v-if="link === 'history'">
              Date <br />
              du dernier dépôt
            </th>
            <th scope="col" class="text-right">
              <p class="mr-2 m-0">Actions</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="table-line mb-3 tr-table height-auto"
            :key="item.codeActivite"
          >
            <td
              class="white-space-default td-table-1-1td"
              :class="getClassRs(item)"
            >
              {{ item.redevabilite }}
              <Info
                href="#"
                cssClass="fr-fi-information-line info a-info"
                :data="iContext(item.contexte)"
                v-if="item.contexte !== null"
                :large="true"
              />
              <p class="label-siret">
                {{ item.precisionRedevabilite }}
              </p>
            </td>
            <td class="td-table-1-1td" :class="getClassRs(item)">
              {{
                item.periodeReference &&
                $filters.dateFormat(item.periodeReference.dateDebut)
              }}
              {{
                item.periodeReference && item.periodeReference.dateDebut
                  ? '-'
                  : ''
              }}
              {{
                item.periodeReference &&
                $filters.dateFormat(item.periodeReference.dateFin)
              }}
            </td>
            <td class="td-table-1-1td" :class="getClassRs(item)">
              {{ $filters.dateFormat(item.dateLimiteDepot) }}
            </td>
            <td
              v-if="link === 'history' || window.width < 760"
              class="td-table-1-1td"
              :class="getClassRs(item)"
            >
              {{ $filters.dateFormat(item.dateDernieDepot) }}
            </td>
            <td class="td-table-1-1td" :class="getClassRs(item)">
              <DeclarationAction
                :actions="item.actions"
                class="color-pr my-auto pointer"
                :detail="true"
                :label="item.redevabilite"
                :tagChapter1="'detail_declaration'"
                :tagChapter2="getCopro(item)"
                :tagChapter3="item.redevabilite"
                :tagButton="true"
                :data="item.contexte"
                :detailsLiens="afficher(item)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="item.detailDGFIP && item.detailDGFIP.message !== null">
        <p class="tva-detail-header rs-fs-875 pre-formatted py-4">
          {{ item.detailDGFIP.message }}
        </p>
      </template>
      <template
        v-if="
          item.detailACOSS &&
          item.detailACOSS.listeDepots &&
          item.detailACOSS.listeDepots.length > 0 &&
          item.categorie &&
          item.categorie === 'RG'
        "
      >
        <p class="tva-detail-header m-auto">
          {{ item.detailACOSS.message }}
        </p>
      </template>

      <table class="table table-borderless sub-table">
        <caption>
          Liste des dépôts ACOSS
        </caption>
        <template
          v-if="
            item.detailACOSS &&
            item.detailACOSS.listeDepots &&
            item.detailACOSS.listeDepots.length > 0
          "
        >
          <thead>
            <tr>
              <th scope="col" class="pr-3">
                Date de <br />
                dépôt
              </th>
              <th scope="col" class="pr-3">
                Numéro de <br />
                fraction
              </th>
              <th scope="col">
                Date de début de <br />
                période de <br />
                rattachement
              </th>
              <th scope="col">
                Date de fin de <br />
                période de <br />
                rattachement
              </th>
              <th scope="col">
                Montant <br />
                déclaré
              </th>
              <th scope="col" v-show="false">
                Montant <br />
                recalculé
              </th>
              <th scope="col">
                Montant de<br />
                déduction
              </th>
              <th scope="col" class="text-right">
                <p class="mr-2 m-0">Actions</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(detailDsn, indexDetail) in item.detailACOSS.listeDepots"
            >
              <tr
                v-for="(bordereau, index) in detailDsn.bordereauxCotisations"
                :key="`${indexDetail}-${index}-detail-dsn`"
                :class="{
                  'bg-grey': index % 2 != 0,
                  'rs-table-small': !isShows[index + indexDetail + index]
                }"
                class="rs-table-3 tr-table-3"
              >
                <td
                  v-if="index == 0 && window.width > 760"
                  :rowspan="detailDsn.bordereauxCotisations.length"
                  class="td-table-3"
                >
                  {{ $filters.dateFormat(detailDsn.dateDepot) }}
                </td>

                <td v-if="window.width <= 760" class="td-table-3 rs-fs-1000">
                  <span
                    :class="{
                      'font-weight-bold': isShows[index + indexDetail + index]
                    }"
                  >
                    Dépôt du : {{ $filters.dateFormat(detailDsn.dateDepot) }}
                  </span>
                  <span
                    v-if="!isShows[index + indexDetail + index]"
                    class="ri-add-fill pointer rs-show-position"
                    @click="switchFlag(index + indexDetail + index)"
                  ></span>
                  <span
                    v-if="isShows[index + indexDetail + index]"
                    class="ri-subtract-fill pointer rs-show-position"
                    @click="switchFlag(index + indexDetail + index)"
                  ></span>
                </td>

                <td
                  v-if="index == 0 && window.width > 760"
                  :rowspan="detailDsn.bordereauxCotisations.length"
                  class="td-table-3"
                >
                  {{ detailDsn.fraction }}
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] && window.width <= 760
                  "
                  class="td-table-3"
                >
                  {{ detailDsn.fraction }}
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] || window.width > 760
                  "
                  class="td-table-3"
                >
                  {{
                    $filters.dateFormat(bordereau.periodePrincipale.dateDebut)
                  }}
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] || window.width > 760
                  "
                  class="td-table-3"
                >
                  {{ $filters.dateFormat(bordereau.periodePrincipale.dateFin) }}
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] || window.width > 760
                  "
                  class="font-weight-bold td-table-3"
                  :class="{ 'text-right': window.width > 760 }"
                >
                  <span class="sr-only">
                    {{
                      $filters.numberSpacedRgaa(bordereau.montantDeclare)
                    }}</span
                  >
                  <span aria-hidden="true">
                    {{ $filters.numberSpaced(bordereau.montantDeclare) }}
                  </span>
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] || window.width > 760
                  "
                  class="font-weight-bold td-table-3"
                  v-show="false"
                >
                  <span class="sr-only">
                    {{
                      $filters.numberSpacedRgaa(bordereau.montantCalcule)
                    }}</span
                  >
                  <span aria-hidden="true">
                    {{ $filters.numberSpaced(bordereau.montantCalcule) }}
                  </span>
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] || window.width > 760
                  "
                  class="font-weight-bold td-table-3"
                >
                  <span class="sr-only">
                    {{
                      $filters.numberSpacedRgaa(bordereau.montantDeduction)
                    }}</span
                  >
                  <span aria-hidden="true">
                    {{
                      $filters.numberSpaced(bordereau.montantDeduction)
                    }}</span
                  >
                </td>
                <td
                  v-if="
                    isShows[index + indexDetail + index] || window.width > 760
                  "
                  class="color-pr d-flex flex-column td-action td-table-3"
                >
                  <DeclarationAction
                    class="flex-column my-auto pointer"
                    :actions="[bordereau.detailBordereauUrssaf]"
                    v-on:detail="openDetailBordereauUrssaf(bordereau.clebrc)"
                    :tagChapter1="'detail_declaration'"
                    :tagChapter2="getCopro(item)"
                    :tagChapter3="item.redevabilite"
                    :tagButton="true"
                    :rsBtnDetail="false"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </template>
        <template v-if="item.detailDGFIP">
          <thead>
            <tr
              v-if="
                item.detailDGFIP.listeDepots &&
                item.detailDGFIP.listeDepots.length > 0
              "
            >
              <th scope="col" class="pr-3">Date et heure du dépôt</th>
              <th scope="col" class="pr-3">Mode de dépôt</th>
              <th scope="col">Régime</th>
              <th scope="col">Formulaires déposés</th>
              <th scope="col" class="text-right">
                <p class="mr-2 m-0">Action</p>
              </th>
            </tr>
          </thead>
          <tbody
            v-if="
              item.detailDGFIP &&
              item.detailDGFIP.listeDepots &&
              item.detailDGFIP.listeDepots.length > 0
            "
          >
            <tr
              v-for="(detail, index) in item.detailDGFIP.listeDepots"
              :key="index"
              :class="{
                'bg-grey': index % 2 != 0,
                'rs-table-small': !isShows[index]
              }"
              class="rs-table tr-table-2"
            >
              <td v-if="window.width > 760" class="td-table-2">
                {{ $filters.dateTimeFormat(detail.dateHeureDepot) }}
              </td>
              <td v-if="window.width <= 760" class="td-table-2 rs-fs-1000">
                <span :class="{ 'font-weight-bold': isShows[index] }">
                  Dépôt du :
                  {{ $filters.dateTimeFormat(detail.dateHeureDepot) }}
                </span>
                <span
                  v-if="!isShows[index]"
                  class="ri-add-fill pointer rs-show-position"
                  @click="switchFlag(index)"
                ></span>
                <span
                  v-if="isShows[index]"
                  class="ri-subtract-fill pointer rs-show-position"
                  @click="switchFlag(index)"
                ></span>
              </td>
              <td
                v-if="isShows[index] || window.width > 760"
                class="td-table-2"
              >
                {{ detail.modeDepot }}
              </td>
              <td
                v-if="isShows[index] || window.width > 760"
                class="td-table-2"
              >
                {{ detail.regime }}
              </td>
              <td
                v-if="isShows[index] || window.width > 760"
                class="td-table-2"
              >
                {{ afficherFormulairesDGFIP(detail) }}
              </td>
              <td
                v-if="window.width > 760"
                class="text-center td-action td-table-2"
              >
                <a
                  v-if="detail.accesAdelie && detail.accesAdelie != undefined"
                  href="#"
                  class="color-pr voir-form"
                  :aria-label="
                    detail.accesAdelie.libelle +
                    ' ' +
                    detail.listeFormulaireDeposes +
                    ' soumis au ' +
                    ' ' +
                    detail.regime +
                    ' déposé en mode ' +
                    detail.modeDepot +
                    ' le ' +
                    formmaterDate(detail.dateHeureDepot)
                  "
                  @click="goToIframe(detail.accesAdelie, $event)"
                  >{{ detail.accesAdelie.libelle }}
                  <span class="ri-arrow-right-line px-2 mx-2 pointer"></span
                ></a>
              </td>
            </tr>
          </tbody>
        </template>
        <thead
          v-if="
            item.detailACOSS &&
            item.detailACOSS.listeDepots &&
            item.detailACOSS.listeDepots.length === 0 &&
            item.detailDGFIP == null
          "
        >
          <td colspan="5">
            <p class="tva-detail-header">
              {{ item.detailACOSS.message }}
            </p>
          </td>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
import { SOURCE_PAGE_DECLARATION_DETAIL } from '../constantes';
import DeclarationAction from '../components/DeclarationAction';
import Info from '../shared/Info';
import Alert from '../shared/Alert';
import SkipLinkMixin from '../mixins/skip-link-mixin';

import { iContext, sendTag, afficherDetail } from '../utils/methodsUtils';
import { mapActions, mapGetters } from 'vuex';
import store from '../store';

export default {
  components: {
    DeclarationAction,
    Info,
    Alert
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      componentPath: this.$route.path,
      isShows: [],
      window: {
        width: 0,
        height: 0
      },
      item: null
    };
  },
  computed: {
    ...mapGetters('declaration', ['detailBordereauUrssaf']),
    ...mapGetters(['siren', 'getRouteData']),
    link() {
      const l =
        this.getRouteData &&
        this.getRouteData.data &&
        this.getRouteData.data.tab
          ? this.getRouteData.data.tab
          : null;
      return l;
    }
  },
  methods: {
    ...mapActions('declaration', ['fetchDetailBordereauUrssaf']),
    ...mapActions(['setPreviousPath', 'setRouteData']),
    afficher(item) {
      return afficherDetail(item, 'DeclarationDetail');
    },
    formmaterDate(date) {
      const dateToFormat = new Date(date);
      return dateToFormat.toLocaleString();
    },
    afficherFormulairesDGFIP(detail) {
      let res = '-';
      if (
        detail.listeFormulaireDeposes &&
        detail.listeFormulaireDeposes.length > 0
      ) {
        res = detail.listeFormulaireDeposes.toString().replaceAll(',', ', ');
      }
      return res;
    },
    goPrevious(event) {
      sendTag('Retour_aux_declarations', 'detail_declaration', '', '');
      if (event) {
        event.preventDefault();
      }
      /*
          table: indique si on est sur le tableau déclarations ou remboursements
          retourDetail: indique que l'on revient d'une page détail
      */
      const routeData = {
        source: SOURCE_PAGE_DECLARATION_DETAIL,
        table: 'declarations',
        retourDetail: true
      };
      this.setRouteDataAndPush(routeData, 'Déclarations');
    },
    iContext(context) {
      return iContext(context);
    },
    async goToIframe(accesAdelie, event) {
      if (event) {
        event.preventDefault();
      }

      const routeData = {
        source: SOURCE_PAGE_DECLARATION_DETAIL,
        uri: accesAdelie.cible
      };

      await this.setRouteData(routeData);
      let routeResolved = this.$router.resolve({
        name: 'Téléservice'
      });
      localStorage.setItem('libelleTeleservice', accesAdelie.libelle);
      this.setPreviousPath(this.$route.path);
      window.open(routeResolved.href, '_self', 'noopener');
    },
    switchFlag: function (index) {
      this.$set(this.isShows, index, !this.isShows[index]);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    async openDetailBordereauUrssaf(clebrc) {
      let object = {};
      object.siren = this.siren;
      object.siret = this.item.siret;
      object.clebrc = clebrc;

      await this.fetchDetailBordereauUrssaf(object);
      const errFonct = store.state.erreur.details.filter(
        (d) =>
          d.id &&
          d.id.length &&
          d.id === 'err-back-declarations-bordereau-urssaf' &&
          d.infoType === 'FUNCTIONAL'
      );

      if (!(errFonct && errFonct.length)) {
        localStorage.setItem('detailDeclaration', JSON.stringify(this.item));
        localStorage.setItem(
          'detailBordereauCotisations',
          JSON.stringify(this.detailBordereauUrssaf)
        );

        const routeData = {
          source: SOURCE_PAGE_DECLARATION_DETAIL,
          item: this.detailBordereauUrssaf
        };
        this.setRouteDataAndPush(
          routeData,
          'Détail du bordereau de cotisations Urssaf'
        );
      }
    },
    getCopro(decla) {
      if (decla.detailDGFIP) {
        return 'impots';
      } else if (decla.detailACOSS) {
        return 'urssaf';
      } else {
        return 'douane';
      }
    },
    getClassRs(item) {
      if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        !item.dateLimiteDepot &&
        !item.dateDernieDepot
      ) {
        return 'td-table-1-2td-periode-ref';
      } else if (
        (!item.periodeReference ||
          (!item.periodeReference.dateDebut &&
            !item.periodeReference.dateFin)) &&
        item.dateLimiteDepot &&
        !item.dateDernieDepot
      ) {
        return 'td-table-1-2td-date-limite-dpt';
      } else if (
        (!item.periodeReference ||
          (!item.periodeReference.dateDebut &&
            !item.periodeReference.dateFin)) &&
        !item.dateLimiteDepot &&
        item.dateDernieDepot
      ) {
        return 'td-table-1-2td-date-dernier-dpt';
      } else if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        item.dateLimiteDepot &&
        !item.dateDernieDepot
      ) {
        return 'td-table-1-3td-periode-ref-dt-lmt-dpt';
      } else if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        !item.dateLimiteDepot &&
        item.dateDernieDepot
      ) {
        return 'td-table-1-3td-periode-ref-dt-dernier-dpt';
      } else if (
        (!item.periodeReference ||
          (!item.periodeReference.dateDebut &&
            !item.periodeReference.dateFin)) &&
        item.dateLimiteDepot &&
        item.dateDernieDepot
      ) {
        return 'td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt';
      } else if (
        item.periodeReference &&
        (item.periodeReference.dateDebut || item.periodeReference.dateFin) &&
        item.dateLimiteDepot &&
        item.dateDernieDepot
      ) {
        return 'td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt';
      }
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData).then(() => {
        this.$router.push({
          name: routeName
        });
      });
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.item = localStorage.getItem('detailDeclaration')
      ? JSON.parse(localStorage.getItem('detailDeclaration'))
      : null;
  }
};
</script>

<style lang="scss" scoped>
// @import '../styles/_tables.scss';
th {
  padding: 0.75rem 0;
  vertical-align: middle;
  background-color: #f8f8f8 !important;
  border-bottom: 2px solid #6a6a6a !important;
  height: 53px;
}
tr {
  height: 53px;
  vertical-align: middle;
}
.td-action {
  height: 53px;
}
.selected-declaration {
  border-bottom: 2px solid;
}
.table-line {
  background: white;
  border-left: solid 3px #000091;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  height: 72px;
  align-items: center;
  font-size: 0.875rem;
  vertical-align: middle;
}
.tva-detail-header {
  text-align: left;
  margin-top: 1rem;
}
@media only screen and (max-width: 760px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  td {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 0.4rem !important;
    font-size: 14px;
    padding-top: 1rem !important;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  td label {
    position: relative;
    top: 20%;
    left: -2%;
    width: 100%;
    white-space: nowrap;
  }

  /*
		Label the data  table -  - 1
		*/
  .td-table-1-1td:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 2
		*/
  .td-table-1-2td-periode-ref:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-2td-periode-ref:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 3
		*/
  .td-table-1-2td-date-limite-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-2td-date-limite-dpt:nth-of-type(2):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }
  /*
		Label the data  table -  - 4
		*/
  .td-table-1-2td-date-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-2td-date-dernier-dpt:nth-of-type(2):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 5
		*/
  .td-table-1-3td-periode-ref-dt-lmt-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-3td-periode-ref-dt-lmt-dpt:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-3td-periode-ref-dt-lmt-dpt:nth-of-type(3):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 6
		*/
  .td-table-1-3td-periode-ref-dt-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-3td-periode-ref-dt-dernier-dpt:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-3td-periode-ref-dt-dernier-dpt:nth-of-type(3):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 7
		*/
  .td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(2):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-3td-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(3):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  /*
		Label the data  table -  - 8
		*/
  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(1):before {
    content: 'Taxe / Impôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(2):before {
    content: 'Période de référence';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(3):before {
    content: 'Date limite de dépôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-1-4td-prd-ref-dt-lmt-dpt-dt-dernier-dpt:nth-of-type(4):before {
    content: 'Date du dernier dépôt';
    color: gray;
    font-size: 12px;
  }

  .row {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .tr-table {
    border-left-width: initial;
    border: solid 1px #ddd;
    border-left: solid 3px #000091;
  }

  .rs-mb-15 {
    margin-bottom: 15px;
  }

  .fr-table--bordered tbody td,
  .fr-table--bordered tbody th {
    border-bottom: 0;
  }
  .fr-input {
    padding-top: 0 !important;
  }
  .rs-table {
    height: 17rem !important;
  }
  .rs-table-3 {
    height: 22rem !important;
  }
  .rs-table-small {
    height: 3.5rem !important;
  }
  .rs-fs-2000 {
    font-size: 2rem;
  }
  .rs-fs-875 {
    font-size: 0.875rem;
  }
  .rs-title-container {
    margin-bottom: 0;
    height: 3.5rem;
  }
  .rs-mb-625 {
    margin-bottom: 0.625rem;
  }

  .td-table-2 {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 0.4rem !important;
    font-size: 14px;
    padding-top: 0.8rem !important;
  }

  .td-table-2:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .td-table-2 label {
    position: relative;
    top: 20%;
    left: -2%;
    width: 100%;
    white-space: nowrap;
  }

  /*
		Label the data - sec table
		*/
  .td-table-2:nth-of-type(1):before {
    content: '';
    color: gray;
    font-size: 12px;
  }
  .td-table-2:nth-of-type(2):before {
    content: 'Mode de dépôt';
    color: gray;
    font-size: 12px;
  }
  .td-table-2:nth-of-type(3):before {
    content: 'Régime';
    color: gray;
    font-size: 12px;
  }
  .td-table-2:nth-of-type(4):before {
    content: 'Formulaire déposés';
    color: gray;
    font-size: 12px;
  }
  .tr-table-2 {
    border-top: 1px solid var(--g400);
    border-bottom: 1px solid var(--g400);
  }
  .rs-fs-1000 {
    font-size: 1rem;
  }
  .rs-show-position {
    float: right;
  }

  .td-table-3 {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 0.4rem !important;
    font-size: 14px;
    padding-top: 0.8rem !important;
  }

  .td-table-3:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .td-table-3 label {
    position: relative;
    top: 20%;
    left: -2%;
    width: 100%;
    white-space: nowrap;
  }

  /*
		Label the data - sec table
		*/
  .td-table-3:nth-of-type(1):before {
    content: '';
    color: gray;
    font-size: 12px;
  }
  .td-table-3:nth-of-type(2):before {
    content: 'Numéro de fraction';
    color: gray;
    font-size: 12px;
  }
  .td-table-3:nth-of-type(3):before {
    content: 'Date de début de période de rattachement';
    color: gray;
    font-size: 12px;
  }
  .td-table-3:nth-of-type(4):before {
    content: 'Date de fin de période de rattachement';
    color: gray;
    font-size: 12px;
  }
  .td-table-3:nth-of-type(5):before {
    content: 'Montant déclaré';
    color: gray;
    font-size: 12px;
  }
  .td-table-3:nth-of-type(6):before {
    content: 'Montant recalculé';
    color: gray;
    font-size: 12px;
  }
  .td-table-3:nth-of-type(7):before {
    content: 'Montant de déduction';
    color: gray;
    font-size: 12px;
  }
  .tr-table-3 {
    border-top: 1px solid var(--g400);
    border-bottom: 1px solid var(--g400);
  }
}
@media only screen and (min-width: 760px) {
  .row {
    width: 78%;
    margin: auto;
  }
  .color-label {
    color: $base-color;
  }
}

a:focus {
  border-radius: 0;
  box-shadow: none;
}

.voir-form {
  box-shadow: none;
}

.pre-formatted {
  white-space: pre-line;
}

.label-siret {
  font-style: italic;
  font-weight: 700;
}

.height-auto {
  height: auto !important;
}
</style>
